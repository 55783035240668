export default {
  baseUrl: 'https://api-blr.opdnext.com/hims/api/',
  linkUrl: 'https://api-blr.opdnext.com/',
  queue: {
    maxDoctors: 4
  },
  company: {
    name: 'OPD Next',
    address:
      'Rajive Gandhi Infotech Park, Hinjewadi Phase 3, Maan, Pune 411057',
    phone: '+91 9594619799',
    email: {
      info: 'support@opdnext.com',
      support: 'support@opdnext.com'
    },
    // locationUrl:
    // 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.8995698194185!2d73.68696067380233!3d18.578565967402895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bb720282bedd%3A0x6902135a38d49e69!2sHMHQ%2BFQG%20Eon%20Homes%20Tower-A3%2C%20EON%20HOMES%2C%20Phase%203%2C%20Hinjawadi%20Rajiv%20Gandhi%20Infotech%20Park%2C%20Hinjawadi%2C%20Pune%2C%20Pimpri-Chinchwad%2C%20Maharashtra%20411057!5e0!3m2!1sen!2sin!4v1722879765670!5m2!1sen!2sin',
    statistics: [
      {
        name: 'Engagement (%)',
        value: 85,
        iconClass: 'fa-solid fa-people-arrows'
      },
      {
        name: 'Satisfaction Rate (%)',
        value: 95,
        iconClass: 'fa-regular fa-face-smile'
      },
      {
        name: 'Wait Time Reduction (%)',
        value: 40,
        iconClass: 'fas fa-clock-rotate-left'
      },
      {
        name: 'Data Accuracy',
        value: 99,
        iconClass: 'fas fa-check-double'
      }
    ],
    socialAccounts: [
      {
        name: 'X',
        url: null,
        iconClass: 'bi bi-twitter-x'
      },
      {
        name: 'facebook',
        url: null,
        iconClass: 'bi bi-facebook'
      },
      {
        name: 'instagram',
        url: null,
        iconClass: 'bi bi-instagram'
      },
      {
        name: 'linkedin',
        url: null,
        iconClass: 'bi bi-linkedin'
      }
    ],
    privacyPolicy: {
      effectiveDate: '27/08/2024'
    },
    termsOfService: {
      effectiveDate: '27/08/2024'
    },
    cookiePolicy: {
      effectiveDate: '27/08/2024'
    },
    securityPolicy: {
      effectiveDate: '27/08/2024'
    }
  }
};
