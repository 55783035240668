import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
  compose
} from 'redux';
import ReduxThunk from 'redux-thunk';
import appReducer from './reducers/app';
import userReducer from './reducers/user';
import patientReducer from './reducers/patient';
import doctorReducer from './reducers/doctor';
import appointmentReducer from './reducers/appointment';
import companyReducer from './reducers/company';

// Import the package
import logger from 'redux-logger';

const applicationReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  patient: patientReducer,
  doctor: doctorReducer,
  appointment: appointmentReducer,
  company: companyReducer
});

const configureStore = () => {
  return createStore(
    applicationReducer,
    compose(applyMiddleware(ReduxThunk, logger))
  );
};
export default configureStore;
