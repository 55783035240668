export default {
  SUPER_ADMIN: {
    name: 'Super Admin',
    value: 'SUPER_ADMIN',
    color: 'primary'
  },
  COMPANY_ADMIN: {
    name: 'Company Admin',
    value: 'COMPANY_ADMIN',
    color: 'secondary'
  },
  BRANCH_ADMIN: {
    name: 'Branch Admin',
    value: 'BRANCH_ADMIN',
    color: 'error'
  },
  DOCTOR: {
    name: 'Doctor',
    value: 'DOCTOR',
    color: 'success'
  },
  STAFF: {
    name: 'Staff',
    value: 'STAFF',
    color: 'info'
  },
  FINANCE: {
    name: 'Finance',
    value: 'FINANCE',
    color: 'black'
  },
  BRANCH_CROSS_ACCCOUNT: {
    name: 'Cross Branch User',
    value: 'BRANCH_CROSS_ACCCOUNT',
    color: 'info'
  }
};
