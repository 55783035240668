import moment from 'moment';

export const getTimeStamp = (date) => {
  let now = new Date(date);
  return (
    now.getHours() +
    ':' +
    (now.getMinutes() < 10 ? '0' + now.getMinutes() : now.getMinutes()) +
    ':' +
    (now.getSeconds() < 10 ? '0' + now.getSeconds() : now.getSeconds())
  );
};

const defaultOptions = {
  significantDigits: 2,
  thousandsSeparator: ',',
  decimalSeparator: '.',
  symbol: '₹'
};

export const currencyFormatter = (value, options) => {
  if (typeof value !== 'number') value = 0.0;
  options = { ...defaultOptions, ...options };
  value = value.toFixed(options.significantDigits);

  const [currency, decimal] = value.split('.');
  return `${options.symbol} ${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator
  )}${options.decimalSeparator}${decimal}`;
};

export const futureDateByDays = () => {
  let dateOne = new Date();
  let TotalTime = Math.ceil(365 * (1000 * 3600 * 24));
  let difference = dateOne.getTime() + TotalTime;

  return new Date(difference);
};

export const capitalizeString = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const compareIfDatesEqual = (d1, d2) => {
  let date1 = new Date(d1).getTime();
  let date2 = new Date(d2).getTime();

  if (date1 < date2 || date1 > date2) {
    return 0;
  }
  return 1;
};

export const dataFormatDateWise = async (dataArray) => {
  let dateFormattedMessages = dataArray.reverse().map((message) => {
    return {
      ...message,
      modifiedDate: new Date(
        message.modifiedDate ? message.modifiedDate : message.createdDate
      ).toDateString(),
      createDate: new Date(message.createdDate).toDateString()
    };
  });
  return dateFormattedMessages.reduce((total, currentValue) => {
    total[currentValue.modifiedDate] = total[currentValue.modifiedDate] || [];
    total[currentValue.modifiedDate].push(currentValue);
    return total;
  }, Object.create(null));
};

export const convertSlotDataToCalendarData = (slotsData) => {
  return (
    slotsData &&
    slotsData.map((item) => {
      return {
        ...item,
        title: item.status,
        end: new Date(moment(new Date(item.slotDateTime)).add(30, 'minutes')),
        start: new Date(item.slotDateTime)
      };
    })
  );
};

export const calculateBMI = (heightCMs, weightKgs) => {
  return weightKgs / ((heightCMs / 100) * (heightCMs / 100));
};

export const encodePassword = (password) => {
  return password;
};
