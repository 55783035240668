// import { storeToken } from '../../helpers/asyncstorage';
import { encodePassword } from 'src/helpers/utils';
import {
  createData,
  createDataWithToken,
  readDataWithToken
} from '../../helpers/db';
import { showSuccessToaster } from '../../helpers/toaster';
import { httpErrorResponse, LOADING_START, LOADING_STOP } from './app';
import { fetchCompanyDetails } from './company';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const UPDATED_USER_PROFILE = 'UPDATED_USER_PROFILE';
export const SEND_RESET_PASSWORD_EMAIL = 'SEND_RESET_PASSWORD_EMAIL';
export const SESSION_STORAGE_TOKEN = 'SESSION_STORAGE_TOKEN';
export const FETCH_USERS = 'FETCH_USERS';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';

/**
 * This is to create user by branch admin or company admin after company is registered
 * can be used after login
 *
 * @param {*} formData
 * @param {*} successCallback
 * @returns
 */
export const createUser = (formData, successCallback) => {
  return (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    dispatch({ type: LOADING_START });
    const currentDate = new Date().toISOString();
    const userName = formData.emailId
      ? formData.emailId
      : (
          formData.firstName +
          '.' +
          formData.lastName +
          '@' +
          formData.mobileNumber.substring(0, 4)
        ).toLowerCase();
    const requestData = {
      payload: {
        profile: {
          ...formData,
          branchId: formData.branchId
            ? formData.branchId
            : getState().company.workspaceBranch,
          companyId: userDetails.user.profile.companyId,
          userName: userName,
          degree: formData.degree
            ? formData.degree + ':' + formData.degreeName
            : 'NA',
          userDetails: userName,
          createdBy: userDetails.user.account.userName,
          createdDate: currentDate,
          modifiedBy: userDetails.user.account.userName,
          modifiedDate: currentDate
        },
        account: {
          userName: userName,
          password: encodePassword(
            formData.firstName.substring(0, 3) +
              '_' +
              formData.mobileNumber.substring(6, 9)
          ),
          mfSecretToken: null,
          resetPassword: true,
          isPasswordExpired: false,
          enable: true,
          authorities: formData.authorities,
          createdBy: userDetails.user.account.userName,
          createdDate: currentDate,
          modifiedBy: userDetails.user.account.userName,
          modifiedDate: currentDate,
          isActivationPending: false
        }
      }
    };

    createDataWithToken('users/v1/save', userDetails.token, requestData)
      .then((response) => {
        successCallback(response);
        dispatch({ type: LOADING_STOP });
        if (formData.userName) {
          showSuccessToaster('User updated successfully!!!');
        } else {
          showSuccessToaster('User registration success!!!');
        }
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

/**
 * This is to for external user to register
 * This  can be used for external user before login
 * @param {*} formData
 * @param {*} successCallback
 * @returns
 */
export const registerUser = (formData, successCallback) => {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_START });
    const userName = formData.emailId
      ? formData.emailId
      : (
          formData.firstName +
          '.' +
          formData.lastName +
          '@' +
          formData.mobileNumber.substring(0, 4)
        ).toLowerCase();
    const currentDate = new Date().toISOString();
    const requestData = {
      payload: {
        profile: {
          branchId: 0,
          companyId: 0,
          userName: userName,
          title: formData.title,
          firstName: formData.firstName,
          middleName: formData.middleName || '',
          lastName: formData.lastName,
          mobileNumber: formData.mobileNumber,
          emailId: formData.emailId,
          degree: formData.degree
            ? formData.degree + ':' + formData.degreeName
            : 'NA',
          userDetails: '',
          createdBy: userName,
          createdDate: currentDate,
          modifiedBy: userName,
          modifiedDate: currentDate,
          dob: formData.dob,
          profileType: 'BUSINESS'
        },
        account: {
          userName: userName,
          password: encodePassword(formData.password),
          mfSecretToken: '',
          resetPassword: true,
          isPasswordExpired: true,
          enable: true,
          authorities: ['SUPER_ADMIN'],
          createdBy: userName,
          createdDate: currentDate,
          modifiedBy: userName,
          modifiedDate: currentDate,
          isActivationPending: true
        },
        company: {
          companyId: 0,
          companyName: formData.companyName,
          enable: true,
          branches: [
            {
              branchId: 0,
              companyId: 0,
              branchName: formData.branchName,
              enable: true,
              createdBy: userName,
              createdDate: currentDate,
              modifiedBy: userName,
              modifiDate: currentDate,
              contact: formData.mobileNumber,
              address: ''
            }
          ],
          createdBy: userName,
          createdDate: currentDate,
          modifiedBy: userName,
          modifiDate: currentDate
        }
      }
    };

    createData('users/v1/user/signup', requestData, {
      catpcha_requestId: getState().app.captcha.requestId,
      catpcha_value: formData.captchaValue
    })
      .then((response) => {
        dispatch({ type: LOADING_STOP });
        showSuccessToaster('Congratulations! You have registered successfully');
        showSuccessToaster('Please login to activate your profile');
        successCallback(response);
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

/**
 * Fetch user details by token after login
 *
 * @param {*} token
 * @param {*} successCallback
 * @param {*} logoutCallback
 * @returns
 */
export const fetchUserDetails = (token, successCallback, logoutCallback) => {
  return async (dispatch) => {
    readDataWithToken('users/v1/user/loggedinuser', token)
      .then((response) => {
        dispatch({
          type: USER_LOGIN,
          payload: {
            token: token,
            user: response
          }
        });
        dispatch(fetchCompanyDetails(response.profile.companyId));
        successCallback(response);
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e, logoutCallback));
      });
  };
};

export const login = (email, password, successCallback) => {
  return async (dispatch) => {
    dispatch({ type: 'LOADING_START' });
    const requestData = {
      userName: email,
      password: encodePassword(password)
    };
    await createData('users/v1/login', requestData)
      .then(async (response) => {
        dispatch({
          type: USER_LOGIN,
          payload: response
        });
        dispatch({ type: LOADING_STOP });
        showSuccessToaster('Welcome Back !!!');
        successCallback(response);
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

export const logout = (callback) => {
  return async (dispatch) => {
    if (sessionStorage.getItem('TOKEN')) {
      dispatch({ type: LOADING_START });
      createDataWithToken(
        'users/v1/logout',
        sessionStorage.getItem('TOKEN')
      ).then(() => {
        sessionStorage.removeItem('TOKEN');
        dispatch({ type: USER_LOGOUT });
        callback();
      });
      dispatch({ type: LOADING_STOP });
    } else {
      callback();
    }
  };
};

/**
 * This for forgot password change using email
 * @param {*} formData
 * @param {*} callback
 * @returns
 */
export const sendPasswordResetEmail = (formData, callback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_START });
    createData(`users/v1/reset/password/${formData.userName}/email`, null, {
      catpcha_requestId: getState().app.captcha.requestId,
      catpcha_value: formData.captchaValue
    })
      .then((response) => {
        if (response.status === 204) {
          showSuccessToaster('Email sent successfully');
          callback();
        }
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

/**
 * To verify the OTP and change password using forgot password link
 * @param {*} formData
 * @param {*} callback
 * @returns
 */
export const verifyOTPAndChangePassword = (formData, callback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_START });
    const requestData = {
      payload: {
        ...formData,
        password: encodePassword(formData.password),
        confirmPassword: undefined
      }
    };
    createData(`users/v1/reset/password/verify`, requestData, {
      catpcha_requestId: getState().app.captcha.requestId,
      catpcha_value: formData.captchaValue
    })
      .then((response) => {
        if (response.status === 204) {
          showSuccessToaster('Your password resetting is successful');
          callback();
        }
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

/**
 * After login user password update by using change password functionality
 * @param {string} password
 * @returns
 */
export const changePassword = (password) => {
  return async (dispatch, getState) => {
    const userDetails = getState().user.userDetails;
    const requestData = {
      payload: encodePassword(password)
    };
    dispatch({ type: LOADING_START });
    createDataWithToken(
      `users/v1/change/password`,
      userDetails.token,
      requestData
    )
      .then(() => {
        showSuccessToaster('Password reset success');
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

/**
 * This is to activate user on first time login after user creation
 *
 * @param {*} userName
 * @param {*} callback
 * @returns
 */
export const sendOTPWithUsername = (userName, callback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_START });
    createDataWithToken(
      'users/v1/user/account/activate/' + userName,
      getState().user.userDetails.token,
      {}
    )
      .then((response) => {
        if (!response.error) {
          showSuccessToaster('OTP Sent successfully');
          callback();
        }
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

/**
 * This to verify OTP for user activation and activating user
 * @param {*} userName
 * @param {*} token
 * @param {*} callback
 * @returns
 */
export const verifyUserWithOTP = (userName, token, callback) => {
  return async (dispatch, getState) => {
    dispatch({ type: LOADING_START });
    createDataWithToken(
      'users/v1/user/account/activate/verify',
      getState().user.userDetails.token,
      {
        payload: {
          userName,
          token
        }
      }
    )
      .then((response) => {
        if (!response.error) {
          dispatch({ type: USER_LOGOUT });
          showSuccessToaster(
            'Profile verified successfully. Please login again!'
          );
          callback();
        }
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

/**
 * This is to fetch all users of company on the basis of company id and branchId
 * pagination enabled
 *
 * @param {*} companyId
 * @param {*} branchId
 * @param {*} pageNo
 * @param {*} pageSize
 * @param {*} callback
 * @returns
 */
export const fetchUsersForCompany = (
  companyId,
  branchId,
  pageNo,
  pageSize,
  callback
) => {
  return (dispatch, getState) => {
    const requestData = {
      pageNo: pageNo || 0,
      pageSize: pageSize || 10,
      sortBy: 'createdDate',
      sortDirection: 'DESC',
      filters: {
        companyId: companyId
      }
    };

    if (branchId) {
      requestData.filters.branchId = branchId;
    }
    createDataWithToken(
      'users/v1/user/find',
      getState().user.userDetails.token,
      requestData
    )
      .then((response) => {
        dispatch({
          type: FETCH_USERS,
          payload: response.data,
          totalCount: response.totalCount
        });
        callback(response);
        dispatch({ type: LOADING_STOP });
      })
      .catch((e) => {
        dispatch(httpErrorResponse(e));
      });
  };
};

/**
 * This is for user search usign name string and branch id
 * Pagination enabled
 *
 * @param {*} firstNameString
 * @param {*} branchId
 * @param {*} page
 * @param {*} rowsPerPage
 * @param {*} callback
 * @returns
 */
export const fetchMatchingUsers = (
  firstNameString,
  branchId,
  page,
  rowsPerPage,
  callback
) => {
  return (dispatch, getState) => {
    const requestData = {
      pageNo: page,
      pageSize: rowsPerPage,
      sortBy: 'firstName',
      sortDirection: 'ASC',
      filters: {
        firstName: firstNameString,
        branchId: branchId
      }
    };
    if (firstNameString) {
      createDataWithToken(
        'users/v1/user/find/matching',
        getState().user.userDetails.token,
        requestData
      )
        .then((response) => {
          dispatch({
            type: FETCH_USERS,
            payload: response.data,
            totalCount: response.totalCount
          });
          callback(response);
          dispatch({ type: LOADING_STOP });
        })
        .catch((e) => {
          dispatch(httpErrorResponse(e));
        });
    }
  };
};
