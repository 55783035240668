import { UPDATE_WORKSPACE_BRANCH } from '../actions/company';
import {
  SESSION_STORAGE_TOKEN,
  FETCH_USERS,
  SET_SELECTED_USER,
  UPDATED_USER_PROFILE,
  USER_LOGIN,
  USER_LOGOUT
} from '../actions/user';

const initialState = {
  users: [],
  selectedUser: null
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        userDetails: action.payload
      };
    case UPDATED_USER_PROFILE:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          appUserEntity: {
            ...state.userDetails.appUserEntity,
            ...action.payload
          }
        }
      };
    case SESSION_STORAGE_TOKEN:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          token: action.payload
        }
      };
    case FETCH_USERS:
      return {
        ...state,
        users: action.payload,
        totalCount: action.totalCount
      };
    case SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload
      };
    case UPDATE_WORKSPACE_BRANCH:
      return {
        ...state,
        users: []
      };
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};
